*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

html:focus-within {
    scroll-behavior: smooth;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

input,
button,
textarea,
select {
    font: inherit;
}

html,
body {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    font-size: 1rem;
    line-height: 1.5;
}
