body {
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    color: var(--foreground-color);
    border-top: 0.5rem solid var(--offwhite-color);
    text-shadow: 1px 2px 1px black;

    @extend %body-font;
}

body>header {
    display: flex;
    gap: 1rem;
    padding: 1rem;

    max-width: $xl;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;

    @include up-to($md) {
        justify-content: center;
    }

    &>.icon {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;

        &>a {
            display: contents;

            &>img {
                max-height: 8rem;
                width: auto;
                filter: drop-shadow(1px 2px 1px black);

                @include up-to($md) {
                    max-height: 4rem;
                }
            }

            &>.title {
                flex: 1 1 auto;
                text-transform: uppercase;
                text-decoration: none;
                color: var(--foreground-color);
                line-height: 1;
                display: flex;
                gap: 0.5rem;
                flex-direction: column;


                &>h1 {
                    font-size: 4rem;

                    @include up-to($md) {
                        font-size: 3.5rem;
                    }

                    @extend %header-font;
                }

                &>sub {
                    font-size: 1rem;
                    font-style: oblique;

                    @include up-to($md) {
                        display: none;
                    }
                }
            }
        }
    }

    &>.nav {
        display: flex;
        align-items: center;
        justify-content: center;

        @include up-to($md) {
            display: none;
        }

        &>li>a {
            padding: 0 0.5em;
            color: var(--foreground-color);
            text-decoration: none;
            font-size: 1.5rem;

            border-left: 1px solid var(--foreground-color);

            &:hover {
                // text-decoration: underline;
                color: var(--offwhite-color);
            }
        }

        &>li:first-child>a {
            border-left: 0 none transparent;
        }
    }
}

#home,
#character,
#cast {
    padding: 0 1rem;
}

#home {
    &>main.content {
        max-width: 70ch;
        margin: 0 auto;

        @extend %content;
    }

    &>ul.cards {
        display: flex;
        flex-direction: column;
        max-width: $xl;
        margin: 0 auto;
        padding: 2rem 0;
        gap: 2rem;

        %card-look {
            // --background-color: var(--offwhite-color);
            // --foreground-color: var(--black-color);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: var(--background-color);
            color: var(--foreground-color);
            // border-top: 4px solid var(--yellow-color);
            text-decoration: none;
            // border-radius: 2px;

            &>h2 {
                line-height: 1;
                font-size: 2rem;
                padding: 1rem 0;
                color: var(--offwhite-color);
                display: flex;
                align-items: center;
                width: 100%;
                gap: 0.5rem;

                &:before,
                &:after {
                    @extend %around-line;
                }

                @extend %header-font;
            }
        }

        &>li {
            display: contents;

            &.social {
                @extend %card-look;
                // border-bottom: 1px solid var(--offwhite-color);

                &>ul {
                    display: flex;
                    gap: 3rem;
                    padding: 2rem 0;
                    width: 100%;
                    justify-content: center;

                    &>li {
                        display: contents;
                    }

                    &>li>a {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        text-decoration: none;
                        color: var(--foreground-color);
                        font-size: 1.5rem;
                        gap: 0.5rem;
                        max-width: 8rem;
                        flex: 1 0 auto;

                        &:hover {
                            filter: brightness(0.7);
                        }

                        &>span {
                            font-size: 0.875rem;
                        }

                        &>img {
                            height: 4rem;
                            aspect-ratio: 1;
                            border-radius: 10%;
                            flex: 0 0 auto;
                            box-shadow: 1px 2px 1px -1px black;

                            &.discord {
                                // blurple
                                padding: 0.5rem;
                                background-color: #5865F2;
                            }

                            &.furaffinity {
                                padding: 0.5rem;
                                background-color: #2e3b41;
                            }

                            &.steam {
                                padding: 0.5rem;
                                background-color: #dee1e8;
                            }
                        }
                    }
                }
            }

            &>a {
                @extend %card-look;

                &:hover>h2 {
                    color: var(--yellow-color);
                }

                &:hover>img {
                    filter: brightness(0.9);
                    background-color: rgba(0, 0, 0, 0.125);
                }

                &>img {
                    max-width: 100%;
                    height: auto;
                    border-radius: 2px;
                }
            }
        }
    }
}

%page-base {
    padding: 2rem 0;

    &>header {
        max-width: $xl;
        display: flex;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 0 0.5rem 2rem;

        @include up-to($md) {
            flex-wrap: wrap;
            border-bottom: 1px solid var(--offwhite-color);
            padding-bottom: 1rem;
            margin-bottom: 1rem;
        }

        &:after {
            color: var(--offwhite-color);
            @extend %around-line;

            @include up-to($md) {
                display: none;
            }
        }

        &>.audio {
            display: flex;
            flex-direction: column;
            // align-items: center;
            gap: 0.5rem;
            order: 1;
        }
    }
}

#post {
    @extend %page-base;

    &>header {
        &>.title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0.25rem;

            &>h1 {
                line-height: 1;
                font-size: 2.5rem;
                margin: 0;
                padding: 0;
                color: var(--offwhite-color);
                text-transform: uppercase;

                @include up-to($md) {
                    font-size: 2rem;
                }

                @extend %header-font;
            }

            &>sub {
                font-size: 1.2rem;
                font-style: oblique;
            }
        }
    }

    &>main.content {
        font-size: 1.25rem;
        max-width: 70ch;
        margin: 0 auto;

        @extend %content;
    }
}

#character {
    @extend %page-base;

    &>header {
        &>.left {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
        }

        &>.left>img {
            flex: 0 0 auto;
            max-width: 8rem;
            max-height: 8rem;
            border-radius: 2px;

            @include up-to($md) {
                max-width: 4rem;
                max-height: 4rem;
            }
        }

        &>.left>.title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;

            &>h1 {
                line-height: 1;
                font-size: 3rem;
                margin: 0;
                padding: 0;
                color: var(--offwhite-color);
                text-transform: uppercase;

                @include up-to($md) {
                    font-size: 2rem;
                }

                @extend %header-font;
            }

            &>sub {
                font-size: 1.5rem;
                font-style: oblique;
            }
        }
    }

    &>main.content {
        font-size: 1.25rem;
        max-width: 70ch;
        margin: 0 auto;

        @extend %content;
    }
}

#scene {
    @extend %page-base;

    header>h1 {
        line-height: 1;
        font-size: 3rem;
        margin: 0;
        padding: 0;
        color: var(--offwhite-color);
        text-transform: uppercase;

        @include up-to($md) {
            font-size: 2rem;
        }

        @extend %header-font;
    }

    &>section.description {
        max-width: $xl;
        font-size: 1.5rem;
        margin: 0 auto;
        padding: 0 2rem 2rem;
        font-style: italic;
        line-height: 1.5;
        text-align: center;
    }

    &>main.content {
        font-size: 1.25rem;
        // max-width: 70ch;
        max-width: $xl;
        margin: 0 auto;

        @extend %content;

        &>iframe {
            width: 100%;
            aspect-ratio: 16 / 9;
        }
    }
}

#cast {
    max-width: $xl;
    margin: 0 auto;

    &>section {
        padding-bottom: 2rem;

        &:last-child {
            padding-bottom: 0;
        }

        &>h1 {
            display: flex;
            line-height: 1;
            font-size: 3rem;
            margin: 0;
            padding: 0;
            color: var(--offwhite-color);
            text-transform: uppercase;
            align-items: center;
            gap: 1rem;

            @extend %header-font;

            &:after {
                @extend %around-line;
            }
        }

        &>ul {
            padding: 1rem;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1rem;

            @include up-to($xl) {
                grid-template-columns: repeat(3, 1fr);
            }

            @include up-to($lg) {
                grid-template-columns: repeat(2, 1fr);
            }

            @include up-to($md) {
                grid-template-columns: 1fr;
            }

            &>li {
                display: contents;
            }

            &>li>a {
                --active-color: var(--offwhite-color);
                grid-column: span 1;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                color: var(--foreground-color);
                text-decoration: none;
                border-bottom: 1px solid var(--active-color);
                padding: 0 0.5rem 1rem;
                border-radius: 2px;

                &:hover {
                    --active-color: var(--yellow-color);
                }

                &>.head {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    align-items: center;

                    &>img {
                        max-width: 100%;
                    }

                    &>h2 {
                        line-height: 1;
                        font-size: 2.2rem;
                        margin: 0;
                        padding: 0;
                        color: var(--active-color);
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        width: 100%;
                        text-align: center;

                        @extend %header-font;

                        &:before,
                        &:after {
                            @extend %around-line;
                            margin-bottom: -0.25rem;
                        }

                    }
                }

                &>.description {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    text-align: justify;
                }
            }
        }

        &.side>ul {
            grid-template-columns: repeat(6, 1fr);

            &>li>a>.head>h2 {
                font-size: 1.25rem;
            }
        }
    }
}

%collection-base {
    max-width: $xl;
    margin: 0 auto;

    &>section {
        &>h1 {
            display: flex;
            line-height: 1;
            font-size: 3rem;
            margin: 0;
            padding: 0;
            color: var(--offwhite-color);
            text-transform: uppercase;
            align-items: center;
            gap: 1rem;

            @extend %header-font;

            &:after {
                @extend %around-line;
            }
        }

        &>ul {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 1rem 0;

            &>li {
                display: contents;
            }
        }
    }
}

#scenes {
    @extend %collection-base;

    &>section>ul>li>a {
        display: flex;
        gap: 1rem;
        color: var(--foreground-color);
        text-decoration: none;
        align-items: center;

        &>.head {
            flex: 0 8 auto;
            line-height: 1;

            &>img,
            &>picture {
                max-width: 100%;
                max-height: 100%;
            }
        }

        &>.description {
            flex: 1 1 90%;

            &>h2 {
                line-height: 1;
                font-size: 2.5rem;
                margin: 0;
                padding: 0;
                gap: 0.5rem;
                width: 100%;
                // text-align: center;

                @extend %header-font;
            }

            &>p {
                font-size: 1rem;
                line-height: 1.5rem;
                text-align: justify;
                padding: 0.5rem 0;
            }
        }
    }
}

#post-list {
    @extend %collection-base;

    &>section>ul {
        padding: 1rem;
    }

    &>section>ul>li>a {
        display: flex;
        gap: 1rem;
        color: var(--foreground-color);
        text-decoration: none;
        align-items: center;
        flex-direction: column;

        &>.head {
            line-height: 1;
            display: flex;
            color: var(--offwhite-color);
            align-items: center;
            width: 100%;
            gap: 0.5rem;

            &:after {
                @extend %around-line;
            }

            &>.title {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                &>h2 {
                    line-height: 1;
                    font-size: 2.5rem;
                    margin: 0;
                    padding: 0;
                    gap: 0.5rem;
                    width: 100%;

                    @extend %header-font;
                }

                &>sub {
                    color: var(--foreground-color);
                }
            }
        }

        &>.more {
            color: var(--offwhite-color);
            align-self: flex-start;
        }

        &:hover>.more {
            color: var(--yellow-color);
            text-decoration: underline;
        }
    }

    &>ul.pager {
        padding: 1rem 0;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        &>li {
            display: contents;
        }

        &>li>a,
        &>li>span {
            flex: 0 0 auto;
            color: var(--offwhite-color);
            border-top: 1px solid currentColor;
            border-bottom: 1px solid currentColor;
            border-left: 1px solid var(--offwhite-color);
            padding: 0.5rem 1rem;
            text-decoration: none;
        }

        &>li>span {
            color: var(--foreground-color);
            border-top: 1px solid var(--offwhite-color);
            border-bottom: 1px solid var(--offwhite-color);
            border-left: 1px solid var(--offwhite-color);
        }

        &>li.selected>a,
        &>li>a:hover {
            color: var(--yellow-color);
            background-color: rgba(0, 0, 0, 0.25);
        }

        &>li.selected>a {
            color: var(--yellow-color);
            pointer-events: none;
        }

        // The first and last child cannot be anything but an anchor.
        &>li:last-child>a {
            border-bottom-right-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
            border-right: 1px solid currentColor;
        }

        &>li:first-child>a {
            border-bottom-left-radius: 0.25rem;
            border-top-left-radius: 0.25rem;
            border-left: 1px solid currentColor;
        }
    }
}

body>footer {
    display: flex;
    width: 100%;
    height: 10rem;
    // margin-top: 2rem;
    padding: 2rem 0;
    border-top: 1px solid var(--offwhite-color);
    background-color: darken($black-color, 5%);
    position: sticky;
    top: 100%;

    &>.container {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 960px;
        margin: 0 auto;
        font-size: 0.75rem;

        &>.left {
            display: contents;
        }

        &>.left>ul {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            text-align: right;

            &>li>a {
                text-decoration: none;
                color: var(--yellow-color);

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &>.right {
            p {
                max-width: 69ch;
            }
        }
    }
}
