$dusk-color: #784421;
$yellow-color: #d4aa00;
$black-color: #27201b;
$white-color: #f3e5dc;
$offwhite-color: #fbd47d;

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

:root {
    --black-color: #{$black-color};
    --white-color: #{$white-color};
    --dusk-color: #{$dusk-color};
    --yellow-color: #{$yellow-color};
    --offwhite-color: #{$offwhite-color};

    --background-color: var(--black-color);
    --foreground-color: var(--white-color);
    --primary-color: var(--yellow-color);
}

%header-font {
    font-family: 'Barlow Condensed', sans-serif;
}

%body-font {
    font-family: 'Lato', sans-serif;
}

%around-line {
    content: "";
    flex-grow: 1;
    border-bottom: 1px solid currentColor;
    box-shadow: 1px 2px 1px -1px black;
}

@mixin up-to($breakpoint) {
    @media (max-width: $breakpoint) {
        & {
            @content;
        }
    }
}

@mixin down-to($breakpoint) {
    @media (min-width: $breakpoint) {
        & {
            @content;
        }
    }
}
